<template>
  <ContactProfileMain
      :contactProfile="contactProfile"
      :isLoginUserProfile="true"
      @profileUpdate="profileUpdated"
  ></ContactProfileMain>
</template>

<script>
import ContactProfileMain from "@/components/molecule/contact/ContactProfileMain";
import {inject} from "vue";
import handleContact from "@/services/modules/contact";
import {mapMutations} from "vuex";
import handleProfile from "@/services/modules/profile";

export default {
  name: 'ContactMyProfile',
  components: {
    ContactProfileMain:ContactProfileMain,
  },
  data() {
    return {
      shouldMutate: false,
      contactProfile: {}
    }
  },

  methods: {
    ...mapMutations({
        setProfile: 'user/setProfile',
    }),
    profileUpdated(){
      this.shouldMutate = true
      this.getDetails()
    },
    async getDetails(showSuccessMessage = false) {
      this.loading = true;
      try {
        let res = await this.getLoginUserProfile()

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.contactProfile = res.data
          if(this.shouldMutate){
            let profRes = await this.fetchProfileInfo()
            if(profRes.status) this.setProfile(profRes.data)
          }
          if(showSuccessMessage) this.showSuccess(res.message)
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.getDetails(true)
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {fetchProfileInfo} = handleProfile()

    const {
      getLoginUserProfile,
      loading,
    } = handleContact()

    return {
      getLoginUserProfile,
      fetchProfileInfo,
      loading,
      showError,
      showSuccess
    }
  }

}
</script>
